"use client";

import React, { useRef } from "react";
import { Slot } from "@radix-ui/react-slot";

import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";

import { useItemSizeUL } from "@/hooks/useItemSizeUL";
import { HoverCard, HoverCardContent, HoverCardProps, HoverCardTrigger } from "./hover-card";

const buttonVariants = cva(
  "flex items-center justify-center rounded-md text-base p-[10px] font-medium border border-1 border-offset-background hover:border-secondary-dark transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        light: "bg-secondary/30 text-secondary-foreground hover:bg-secondary",
        purple: "bg-purple hover:bg-darkPurple text-primary-light justify-center",
        purpleInvert: "bg-primary-light text-purple outline outline-purple",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        destructiveSubtle: "transition-all duration-200 text-primary hover:underline underline-offset-1 hover:text-red-600",
        outline: "outline outline-input outline-1 bg-background hover:bg-accent hover:text-accent-foreground hover:outline-secondary-dark",
        border: "border border-1 border-offset-background hover:border-secondary-dark",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "text-secondary-dark hover:bg-faintPurple dark:hover:bg-accent",
        link: "text-primary underline-offset-4 hover:underline p-[4px]",
        shine: "group relative h-12 overflow-hidden rounded-md bg-purple px-6 text-neutral-50 transition hover:bg-darkPurple",
        blank: "",
      },
      size: {
        default: "h-auto w-min inline-flex justify-center",
        tight: "h-fit px-2 py-1",
        slim: "h-[36px] px-2",
        sm: "h-9 rounded-md px-3",
        md: "h-10 rounded-md px-4",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
        full: "inline-flex min-w-full w-full h-full justify-center",
        fullTall: "inline-flex min-w-full w-full h-[54px] justify-center",
        fullLine: "flex flex-col w-full justify-center min-w-full",
        fit: "inline-flex w-fit h-fit max-w-full",
        blank: "flex flex-col p-0 m-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  id?: string;
  title?: string;
  asChild?: boolean;
  isLoading?: boolean;
  onClick?: any;
  className?: string | undefined;
  underlineAnimation?: boolean;
  moveInTop?: boolean;
  moveInBottom?: boolean;
  moveOutTop?: boolean;
  moveOutBottom?: boolean;
  moveInRight?: boolean;
  moveInLeft?: boolean;
  moveOutRight?: boolean;
  moveOutLeft?: boolean;
  moveUp?: boolean;
  isSelected?: boolean;
  stopMouseEventPropagation?: boolean;
  hidden?: boolean;
  innerClassName?: string;
  tooltip?: string;
  tooltipDelay?: number;
  tooltipVariant?: HoverCardProps["variant"];
  tooltipSize?: HoverCardProps["size"];
  tooltipClassName?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id = "Submit",
      title,
      className,
      variant,
      size,
      asChild = false,
      isLoading = false,
      onClick,
      underlineAnimation = false,
      moveInTop = false,
      moveInBottom = false,
      moveInLeft = false,
      moveInRight = false,
      moveOutTop = false,
      moveOutBottom = false,
      moveOutLeft = false,
      moveOutRight = false,
      moveUp = false,
      isSelected = false,
      stopMouseEventPropagation = false,
      hidden = false,
      innerClassName = "",
      tooltip,
      tooltipDelay = 700,
      tooltipVariant,
      tooltipSize,
      tooltipClassName,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    const blank = variant === "blank" && size === "blank";

    let compRef = useRef<HTMLDivElement>(null);

    const { upperLeftPosition, width, height } = useItemSizeUL({
      ref: compRef,
    });

    const mergedRef = (node: HTMLButtonElement) => {
      // Assign to compRef

      // Forward the ref
      if (typeof ref === "function") {
        ref(node);
      } else if (ref) {
        (ref as React.MutableRefObject<HTMLButtonElement | null>).current = node;
      }
    };

    const buttonJSX = (
      <button
        id={id}
        ref={mergedRef}
        className={cn(
          "pointer-events-auto relative",
          !blank && buttonVariants({ variant, size }),
          {
            //underlineAnimation: "hover:hover-underline-animation",
            //isClicked: "static-underline",
            underlineAnimation: "",
            blank: "appearance-none",
          },
          className
        )}
        onClick={(e) => {
          if (isLoading) return;
          if (stopMouseEventPropagation) {
            e.stopPropagation();
          }
          if (onClick) onClick(e);
        }}
        {...props}
      >
        <div className={cn("w-full", { "flex items-center justify-center": isLoading })}>
          <span
            className={cn(" whitespace-nowrap", { "flex items-center justify-center": !isLoading, relative: variant === "shine" }, innerClassName, {
              invisible: isLoading,
            })}
          >
            {props.children ? props.children : <h1>{title ?? id ?? "Submit"}</h1>}
          </span>
          {isLoading && <Loader2 className="absolute animate-spin" size={20} />}
        </div>{" "}
        {!isLoading && variant === "shine" && (
          <div className="absolute inset-0 -top-[20px] flex h-[calc(100%+40px)] w-full animate-shine-infinite justify-center blur-[12px]">
            <div className="relative h-full w-8 bg-white/25"></div>
          </div>
        )}
      </button>
    );

    // Check if toolTip has text and wrap buttonJSX accordingly
    if (tooltip) {
      return (
        <HoverCard openDelay={tooltipDelay}>
          <HoverCardTrigger asChild>{buttonJSX}</HoverCardTrigger>
          <HoverCardContent className={cn("w-full", tooltipClassName)} variant={tooltipVariant} size={tooltipSize}>
            {tooltip}
          </HoverCardContent>
        </HoverCard>
      );
    }

    return buttonJSX;
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
